'use client';

import { Fragment, useCallback, useEffect, useState } from 'react';

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Button } from './Button';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';
import { usePathname, useRouter } from '../../navigation';
import { ScrollArea } from '@radix-ui/react-scroll-area';

dayjs.extend(isBetween);

const DB_DATE_FORMAT = 'YYYY-MM-DD';
export type CourseFilterSearchParams = {
	startDate?: string;
	endDate?: string;
	all?: boolean;
};
export default function SeasonPicker({}) {
	const t = useTranslations('SeasonPicker');
	const searchParams = useSearchParams();
	const router = useRouter();
	const pathname = usePathname();

	const currentYear = dayjs().year();
	const springStart = '01-01';
	const springEnd = '06-30';
	const autumnStart = '08-15';
	const autumnEnd = '12-31';
	const currentSpringStart = dayjs(`${currentYear}-${springStart}`);
	const currentSpringEnd = dayjs(`${currentYear}-${springEnd}`);
	const currentAutumnStart = dayjs(`${currentYear}-${autumnStart}`);
	const currentAutumnEnd = dayjs(`${currentYear}-${autumnEnd}`);

	const seasonPicker: SeasonPicker[] = [];

	// monkey do program
	if (dayjs().isBetween(currentSpringStart, currentSpringEnd, 'day', '[]')) {
		// current is spring
		seasonPicker.push({
			startDate: currentSpringStart.format(DB_DATE_FORMAT),
			endDate: currentSpringEnd.format(DB_DATE_FORMAT),
			label: t('spring', { year: currentYear }),
		});
		seasonPicker.push({
			startDate: currentAutumnStart.format(DB_DATE_FORMAT),
			endDate: currentAutumnEnd.format(DB_DATE_FORMAT),
			label: t('autumn', { year: currentYear }),
		});
		seasonPicker.push({
			startDate: dayjs(`${currentYear + 1}-${springStart}`).format(DB_DATE_FORMAT),
			endDate: dayjs(`${currentYear + 1}-${springEnd}`).format(DB_DATE_FORMAT),
			label: t('spring', { year: currentYear + 1 }),
		});
		seasonPicker.push({
			startDate: dayjs(`${currentYear + 1}-${autumnStart}`).format(DB_DATE_FORMAT),
			endDate: dayjs(`${currentYear + 1}-${autumnEnd}`).format(DB_DATE_FORMAT),
			label: t('autumn', { year: currentYear + 1 }),
		});
	} else {
		// current is autumn
		seasonPicker.push({
			startDate: currentAutumnStart.format(DB_DATE_FORMAT),
			endDate: currentAutumnEnd.format(DB_DATE_FORMAT),
			label: t('autumn', { year: currentYear }),
		});
		seasonPicker.push({
			startDate: dayjs(`${currentYear + 1}-${springStart}`).format(DB_DATE_FORMAT),
			endDate: dayjs(`${currentYear + 1}-${springEnd}`).format(DB_DATE_FORMAT),
			label: t('spring', { year: currentYear + 1 }),
		});
		seasonPicker.push({
			startDate: dayjs(`${currentYear + 1}-${autumnStart}`).format(DB_DATE_FORMAT),
			endDate: dayjs(`${currentYear + 1}-${autumnEnd}`).format(DB_DATE_FORMAT),
			label: t('autumn', { year: currentYear + 1 }),
		});
		seasonPicker.push({
			startDate: dayjs(`${currentYear + 2}-${springStart}`).format(DB_DATE_FORMAT),
			endDate: dayjs(`${currentYear + 2}-${springEnd}`).format(DB_DATE_FORMAT),
			label: t('spring', { year: currentYear + 2 }),
		});
	}

	type SeasonPicker = {
		startDate: string;
		endDate: string;
		label: string;
	};

	const initialStartDate =
		searchParams.get('startDate') &&
		seasonPicker.find(s => s.startDate === searchParams.get('startDate'))
			? searchParams.get('startDate')
			: null;

	const [activeStartDate, setActiveStartDate] = useState<string | null>(initialStartDate);

	useEffect(() => {
		const startDate =
			searchParams.get('startDate') &&
			seasonPicker.find(s => s.startDate === searchParams.get('startDate'))
				? searchParams.get('startDate')
				: null;
		setActiveStartDate(startDate);
	}, [searchParams]);

	return (
		<ScrollArea className="flex space-x-1 overflow-x-auto whitespace-nowrap">
			{seasonPicker.map(({ startDate, endDate, label }) => {
				return (
					<Button
						key={`season-picker-${label}`}
						size="small"
						className={`uppercase`}
						intent="secondary"
						variant={activeStartDate === startDate ? 'solid' : 'outlined'}
						onClick={e => {
							e?.preventDefault();

							setActiveStartDate(startDate);

							router.push(
								// @ts-expect-error next-intl bad types
								{
									pathname,
									query: {
										startDate,
										endDate,
									},
								},
								{
									scroll: false,
								},
							);
						}}
					>
						{label}
					</Button>
				);
			})}
			<Button
				size="small"
				className={`uppercase`}
				intent="secondary"
				variant={activeStartDate === 'all' || !activeStartDate ? 'solid' : 'outlined'}
				onClick={e => {
					e?.preventDefault();

					setActiveStartDate('all');

					router.push(
						// @ts-expect-error next-intl bad types, it expects params
						{
							pathname,
						},
						{
							scroll: false,
						},
					);
				}}
			>
				{t('all')}
			</Button>
		</ScrollArea>
	);
}
