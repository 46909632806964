import type { Courseversion } from '../../payload/payload-types';

function getLanguageShortSuffix(language: Courseversion['language']): string {
	const translations = {
		onlyEstonian: 'EST',
		estonianButSomeMaterialsInEnglish: 'EST',
		onlyEnglish: 'ENG',
		estonianAndEnglish: 'EST + ENG',
	};

	return translations[language];
}

export default getLanguageShortSuffix;
