import { Courseversion } from '../../payload/payload-types';

type ShouldShowCourseDiscountCardBadgeParams = {
	courseversion: Courseversion;
};
export const shouldShowCourseDiscountCardBadge = ({
	courseversion,
}: ShouldShowCourseDiscountCardBadgeParams): boolean => {
	const shouldShowDiscountBadge =
		courseversion.discountStatus === 'enabled' && Boolean(courseversion.discountName);

	return shouldShowDiscountBadge;
};
