'use client';
import Image from 'next/image';

import { Link } from '../../../navigation';
import { Course, CoursePicture, Courseversion } from '../../../payload/payload-types';
import dayjs from 'dayjs';
import { useTranslations } from 'next-intl';
import getLanguageShortSuffix from '../../_utilities/get-language-short-suffx';
import { CardBadge } from './CardBadge';
import { shouldShowCourseDiscountCardBadge } from '../../_utilities/should-show-course-card-badge';
import { getDiscountReduction, useTrackEvent } from '../../_providers/TrackEventProvider';
import { useCourseVersion } from '../../_providers/CourseVersion';
import { extractId } from '../../../shared/extract-id';

const CourseDuration: React.FC<{ courseversion: Courseversion }> = ({ courseversion }) => {
	const t = useTranslations();

	const courseDurationWeeks = Math.round(
		dayjs(courseversion.timeEdit.endDate).diff(
			dayjs(courseversion.timeEdit.startDate),
			'week',
			true,
		),
	);

	let courseDurationString = t('course-duration-weeks', { courseDurationWeeks });

	if (courseDurationWeeks < 1) {
		const startDate = dayjs(courseversion.timeEdit.startDate);
		const endDate = dayjs(courseversion.timeEdit.endDate);

		// Calculate the number of days the course touches
		let courseDurationDays = endDate.diff(startDate, 'day') + 1;

		// Ensure the course duration is at least 1 day
		if (courseDurationDays <= 0) {
			courseDurationDays = 1;
		}

		courseDurationString = t('course-duration-days', { courseDurationDays });
	} else if (courseDurationWeeks > 6) {
		const startDate = dayjs(courseversion.timeEdit.startDate);
		const endDate = dayjs(courseversion.timeEdit.endDate);

		// Extract start and end month and year
		const startMonth = startDate.month(); // 0-based month index
		const startYear = startDate.year();
		const endMonth = endDate.month(); // 0-based month index
		const endYear = endDate.year();

		// Calculate the number of months touched
		const courseDurationMonths = (endYear - startYear) * 12 + (endMonth - startMonth) + 1;

		courseDurationString = t('course-duration-months', { courseDurationMonths });
	}

	return <p className="text-xs uppercase text-gold-light">{courseDurationString}</p>;
};

export const CourseListCard: React.FC<{
	course: Course;
}> = ({ course }) => {
	const t = useTranslations('Course');
	// TODO redundant checking, but keep for logging
	let courseversion = course?.courseversions?.[0];

	if (typeof courseversion === 'string') {
		courseversion = course?.courseversions?.find(
			_courseversion => typeof _courseversion !== 'string',
		);
	}
	const trackEvent = useTrackEvent();

	if (!courseversion || !course.slug || typeof courseversion === 'string') {
		console.warn('Courses list err: no course/courseversion. ', {
			courseversionId: courseversion && extractId(courseversion),
			courseSlug: course.slug,
		});
		return null;
	}

	const leadingLecturer = courseversion.lecturers?.find(_lecturer => _lecturer.isLeading);
	const lecturer = leadingLecturer?.lecturer;

	if (typeof courseversion === 'string' || !courseversion) {
		console.error(`Bad courseversion for course: ${course.id}`, courseversion);

		return null;
	}

	if (typeof lecturer === 'string' || !lecturer) {
		console.error(
			`Bad lecturer for course: courseId ${course.id}. Course status: ${course._status}. courseversion: ${courseversion.id}. Lecturer: ${lecturer}`,
		);

		return null;
	}

	if (typeof course.picture === 'string') {
		console.error(`Bad depth coursepicture for course: ${course.id}`, course.picture);

		return null;
	}

	const courseImage = course.picture?.sizes?.card?.url || course.picture.url;

	if (!courseImage) {
		console.error('Card: CourseImage missing ', { coursePicture: course.picture });
	}

	const { selectedCourseVersion } = useCourseVersion();

	return (
		<Link
			onClick={() => {
				trackEvent('course_tile_clicked', {
					course_name: course.title,
					course_discount_value_eur: getDiscountReduction(selectedCourseVersion),
				});
			}}
			href={{
				pathname: '/courses/[slug]',
				params: {
					slug: course.slug,
				},
			}}
			key={course.id}
			className="group relative h-96 w-full max-w-[308px] cursor-pointer rounded-4xl border border-gold-light lg:w-[308px]"
		>
			{/* TODO this will only take into account first courseversion, what if second courseversion has discount? */}
			{shouldShowCourseDiscountCardBadge({ courseversion }) && (
				<CardBadge
					title={courseversion.discountName!}
					subTitle={`${t('until')} ${dayjs(courseversion.discountDeadline).format('DD.MM.YYYY')}`}
					color="red"
				/>
			)}

			<div className="relative h-full w-full">
				<div className="flex h-full flex-col items-center justify-between space-y-6 rounded-4xl bg-muted-navy">
					<div className="rounded-4xl bg-slate-200">
						{courseImage && (
							<Image
								alt={(course.picture as CoursePicture)?.alt}
								className="rounded-4xl"
								src={courseImage}
								width={306}
								height={162}
							/>
						)}
					</div>
					<section className="flex h-[164px] w-[234px] flex-col place-content-between max-[330px]:max-w-[234px] max-[330px]:p-4">
						<div className="space-y-1">
							<div className="flex items-center justify-between">
								<CourseDuration courseversion={courseversion}></CourseDuration>

								<p className="ml-2 mr-0 text-[10px] font-bold uppercase text-muted-blue">
									{getLanguageShortSuffix(courseversion.language)}
								</p>
							</div>

							<p className="line-clamp-2 font-bold leading-6 text-white group-hover:line-clamp-none">
								{course.title}
							</p>
							<p className="text-sm font-medium leading-6 text-muted-blue">
								{lecturer.name}
							</p>
						</div>
					</section>
				</div>

				<div className="absolute inset-0">
					<div className="relative  h-full w-full rounded-4xl border-[12px] border-white transition-all delay-100 duration-300 ease-out group-hover:border-[0px]"></div>
				</div>
			</div>
		</Link>
	);
};
