export const CardBadge: React.FC<{
	color: 'green' | 'red';
	title: string;
	subTitle?: string;
}> = ({ color, title, subTitle }) => {
	const bgMap = {
		['green']: 'bg-success',
		['red']: 'bg-error',
	};
	return (
		<div
			className={`absolute right-0 top-7 z-10 flex max-w-28 flex-col gap-2 rounded-l-xl px-2 py-3 text-center ${bgMap[color]}`}
		>
			<h6 className="text-sm font-bold text-white">{title}</h6>
			{Boolean(subTitle) && <small className="text-xs text-white">{subTitle}</small>}
		</div>
	);
};
