'use client';
import { createContext, useContext, useState, useEffect } from 'react';
import { notFound, useParams } from 'next/navigation';
import type { Courseversion } from '../../../payload/payload-types';
import { useRouter, usePathname } from '../../../navigation';

interface CourseVersionContextType {
	selectedCourseVersion: Courseversion | null;
	setSelectedCourseVersion: (arg0: Courseversion) => void;
	allCourseVersions: Courseversion[] | [];
}

const initialState = {
	selectedCourseVersion: null,
	setSelectedCourseVersion: () => {},
	allCourseVersions: [],
};

const CourseVersionContext = createContext<CourseVersionContextType>(initialState);

export const CourseVersionProvider = ({ children, initialCourseVersion, course }) => {
	const [selectedCourseVersion, setSelectedCourseVersion] =
		useState<Courseversion>(initialCourseVersion);

	const allCourseVersions = course.courseversions;
	const router = useRouter();
	const pathname = usePathname();
	const params = useParams();

	useEffect(() => {
		if (!initialCourseVersion?.id || !selectedCourseVersion?.id) {
			console.error(
				`Course has no courseversions added. courseId: ${selectedCourseVersion?.id}. Courseversion id: ${initialCourseVersion?.id}`,
			);
			return notFound();
		}

		if (initialCourseVersion.id !== selectedCourseVersion.id) {
			router.push(
				// @ts-expect-error next-intl
				{
					pathname,
					params: {
						slug:
							typeof params.slug !== 'string' ? (course.slug as string) : params.slug,
					},
					query: {
						courseversion: selectedCourseVersion.id,
					},
				},
				{ scroll: false },
			);
		}
	}, [selectedCourseVersion]);

	return (
		<CourseVersionContext.Provider
			value={{
				selectedCourseVersion,
				setSelectedCourseVersion,
				allCourseVersions,
			}}
		>
			{children}
		</CourseVersionContext.Provider>
	);
};

type useCourseVersion<> = () => CourseVersionContextType;

export const useCourseVersion: useCourseVersion = () => useContext(CourseVersionContext);
